import { Injectable, signal } from "@angular/core";
import { Subject } from 'rxjs';
import { LoginToken } from '../models/interfaces/user.interfaces';
import { SessionKey } from "../models/session.keys";
import { CryptoService } from './crypto.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  readonly resizingWindow: Subject<Event> = new Subject<Event>();
  readonly initialized = signal(false);

  static setToken(token: LoginToken) {
    ApplicationService.storeSession<LoginToken>(SessionKey.TOKEN, token);
  }

  static getToken() {
    return ApplicationService.readSession<LoginToken>(SessionKey.TOKEN);
  }

  static getDecodedToken() {
    return CryptoService.decodeToken(ApplicationService.getToken());
  }

  static storeSession<T>(key: SessionKey, value: T) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static readSession<T>(key: SessionKey): T {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  }

  static deleteSession(key: string) {
    localStorage.removeItem(key);
  }

  static timeOut(fn: () => void, time: number = 1) {
    const tm = setTimeout(() => {
      fn();
      clearTimeout(tm);
    }, time);
  }
}
