import { HttpClient, HttpContext } from '@angular/common/http';
import { inject, Inject, Injectable } from '@angular/core';
import { DOWNLOAD_REQUEST_CONTEXT } from '@core/enum/core.enum';
import { map, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiBaseService {
  protected readonly http: HttpClient = inject(HttpClient);
  protected readonly baseUrl: string;
  private readonly QUERY: string = '?';
  private readonly AND: string = '&';

  constructor(@Inject(String) url: string) {
    this.baseUrl = url;
  }

  private getDelimiter(query: string): string {
    return query.includes(this.QUERY) ? this.AND : this.QUERY;
  }

  protected addQueryParam(
    query: string = '',
    param: string,
    value: string | number | boolean
  ): string {
    const del: string = this.getDelimiter(query);
    return `${query}${del}${param}=${value}`;
  }

  /**
   * Telecharge un fichier
   * @param url
   * @param name
   * @returns
   */
  download(url: string) {
    return this.http
      .post(`${this.baseUrl}/${url}`, null, {
        context: new HttpContext().set(DOWNLOAD_REQUEST_CONTEXT, true),
        responseType: 'blob',
      })
      .pipe(
        take(1),
        map((blob) => window?.URL?.createObjectURL(blob))
      )
      .subscribe((url) => {
        window?.open(url, '_blank');
        window?.URL?.revokeObjectURL(url);
      });
  }
}
