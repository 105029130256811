import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public readonly toggleMainLoader = signal(false);
  public readonly loginLoader = signal(false);

  removeLoaders() {
    this.toggleMainLoader.set(false);
    this.loginLoader.set(false);
  }
}
