import { Page } from '@san/tools/models';
import { Commune } from '../entity/commune';
import { Couverture } from '../entity/couverture';
import { Etablissement } from '../entity/etablissement';
import { Jour } from '../entity/jour';
import { LibelleEntity } from '../entity/libelle.entity';
import { Moyen } from '../entity/moyen';
import { Prestation } from '../entity/prestation';
import { Specialite } from '../entity/specialite';
import { Ville } from '../entity/ville';

export const DEVISE = 'F CFA';

export interface Help {
  email: string;
  phone: string;
  messagerie: string;
  contact: string;
  web: string;
}

export interface FileOptions {
  maxFiles: number;
  size: number;
  imageFormat: string;
  docFormat: string;
}

export interface AppConf {
  appName: string;
  phoneDigit: number;
  apiUrl: string;
  turnstileKey: string;
  file: FileOptions;
  help: Help;
}

export interface AppEnvironnement {
  villes: Ville[];
  communes: Commune[];
  etablissements: Etablissement[];
  specialites: Specialite[];
  prestations: Prestation[];
  moyens: Moyen[];
  couvertures: Couverture[];
  jours: Jour[];
}

export type SearchTermType =
  | 'prestation'
  | 'etablissement'
  | 'specialite'
  | 'ville'
  | 'commune'
  | 'all';

export interface SearchTerm extends LibelleEntity {
  type: SearchTermType;
  data: LibelleEntity;
}

export interface GpsPosition {
  gps_lat: string;
  gps_lng: string;
}

export interface GlobalSearchTerm {
  term?: SearchTerm;
  city?: SearchTerm;
  gps?: GpsPosition;
  page?: Page;
}
