import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { IconName } from '@san/tools/models';
import { SanButtonIconComponent } from '@san/tools/ui/button-icon';
import { BaseDialogComponent } from '@san/tools/ui/dialog';
import { SanInputComponent } from '@san/tools/ui/input';
import { SanModalComponent } from '@san/tools/ui/modal';
import { CommentParam } from '../../models/interfaces/comment.interface';
import { CommentType } from './../../models/interfaces/comment.interface';
import { SafeHtmlPipe } from '@san/tools/pipes';

@Component({
  selector: 'rdv-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    SanButtonIconComponent,
    SanInputComponent,
    SanModalComponent,
    SafeHtmlPipe,
  ],
})
export class CommentDialogComponent
  extends BaseDialogComponent
  implements OnInit
{
  param: CommentParam;
  form: FormGroup;
  readonly IconName = IconName;
  CommentType = CommentType;

  constructor() {
    super();
    this.param = this.data.content;
  }

  ngOnInit(): void {
    this.form = new FormGroup({ comment: new FormControl('') });
    if (this.param.type === CommentType.REQUIRED) {
      this.commentControl.addValidators(Validators.required);
    }
  }

  get commentControl() {
    return this.form.get('comment');
  }

  get maxlength() {
    return this.param?.maxlength ?? 500;
  }

  get title() {
    return this.param?.titleKey ?? 'shared.comment';
  }

  get label() {
    return this.param?.labelKey ?? 'shared.comment';
  }

  get validationLabel() {
    return this.param?.validationLabelKey ?? 'shared.valider';
  }

  validate() {
    this.close({ content: this.commentControl.value });
  }
}
