import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { PubliqueService } from '../api/publique.service';
import { AppConf } from '../models/interfaces/app-conf.interface';
import { ApplicationService } from './application.service';
import { SsrService } from './ssr.service';
import { TraductorService } from './traductor.service';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class AppconfService {
  private readonly http = inject(HttpClient);
  private appConf: AppConf;

  public getAppConf(): AppConf {
    return this.appConf;
  }

  public get imageExtensions() {
    return this.appConf.file.imageFormat.split(',');
  }

  public get docExtensions() {
    return this.appConf.file.docFormat.split(',');
  }

  public get extensions() {
    return [...this.docExtensions, ...this.imageExtensions];
  }

  public init(): Observable<AppConf> {
    return this.http.get<AppConf>('./app.conf.json').pipe(
      take(1),
      tap((appConf: AppConf) => (this.appConf = cloneDeep(appConf)))
    );
  }

  static initialize(
    appConfService: AppconfService,
    appService: ApplicationService,
    publiqueService: PubliqueService,
    userService: UserService,
    traductor: TraductorService,
    ssrService: SsrService
  ) {
    return new Promise((resolve, reject) => {
      try {
        console.info('Lecture de la configuration...');
        appConfService
          .init()
          .pipe(
            switchMap(() =>
              ssrService.isBrowser() ? publiqueService.appEnv() : of(null)
            )
          )
          .subscribe(async (env) => {
            traductor.changeLanguage('fr');
            if (ssrService.isBrowser()) {
              publiqueService.envData.next(env.data);
              await userService.refreshToken(false);
              appService.initialized.set(true);
            }
            resolve(true);
          });
      } catch (error) {
        reject(error);
      }
    });
  }
}
