import { HttpClient } from "@angular/common/http";
import { computed, inject, Injectable, signal } from "@angular/core";
import { jwtDecode } from "jwt-decode";
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { PatientService } from "../api/patient.service";
import { ProService } from '../api/pro.service';
import { PubliqueService } from "../api/publique.service";
import { ApiResponse } from '../models/dto/response.dto';
import { StatusCode } from "../models/dto/status-code.model";
import { Utilisateur } from '../models/entity/Utilisateur';
import { Patient } from "../models/entity/patient";
import { EmailRequest, LoginRequest, LoginToken, NotificationRequest, PasswordRequest, TokenInfo, VerificationData } from "../models/interfaces/user.interfaces";
import { SessionKey } from "../models/session.keys";
import { ObjectUtility } from "../utils/object.utils";
import { UserDroit, UserType } from './../models/enum/user-type.enum';
import { RefreshTokenRequest } from './../models/interfaces/user.interfaces';
import { ApplicationService } from "./application.service";
import { CryptoService } from './crypto.service';
import { LoaderService } from "./loader.service";
import { NavigationService } from "./navigation.service";
import { NotificationService } from "./notifaction.service";
import { SsrService } from './ssr.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private readonly http = inject(HttpClient);
  private readonly navigationService = inject(NavigationService);
  public readonly notificationService = inject(NotificationService);
  private readonly publiqueService = inject(PubliqueService);
  private readonly loaderService = inject(LoaderService);
  private readonly proService = inject(ProService);
  private readonly patientService = inject(PatientService);
  private readonly ssrService = inject(SsrService);

  token: BehaviorSubject<LoginToken> = new BehaviorSubject<LoginToken>(null);
  // connectedUser: BehaviorSubject<TokenInfo> = new BehaviorSubject<TokenInfo>(null);
  connectedUser = signal<TokenInfo>(null);
  isConnected = computed(() => !!this.connectedUser()?.uuid);
  connectedUserType = computed(() => this.connectedUser()?.type);
  connected = computed(() => this.connectedUser()?.user);
  proIsConnected = computed(() => this.connectedUserType() === UserType.PRO);
  patientIsConnected = computed(() => this.connectedUserType() === UserType.PATIENT);
  connectedPro = computed(() => this.proIsConnected() ? this.connectedUser()?.user as Utilisateur : null);
  connectedPatient = computed(() => this.patientIsConnected() ? this.connectedUser()?.user as Patient : null);
  medecinIsConnected = computed(() => !!this.connectedPro()?.medecin?.id);
  isAdmin = computed(() => this.proIsConnected() && this.connectedPro()?.droit === UserDroit.ADMIN);
  isUserManager = computed(() => this.proIsConnected() && [UserDroit.ADMIN, UserDroit.USER_MANAGER].includes(this.connectedPro()?.droit));
  isEntityManager = computed(() => this.proIsConnected() && [UserDroit.ADMIN, UserDroit.ENTITY_MANAGER].includes(this.connectedPro()?.droit));
  dashboardLink = computed(() => this.proIsConnected() ? '/pro' : '/patient');

  /**
   * Login user
   * @param type
   * @param request
   * @returns
   */
  connect(type: UserType, request: LoginRequest): Observable<ApiResponse<LoginToken>> {
    return type === UserType.PRO ? this.publiqueService.connectPro(request) : this.publiqueService.connectPatient(request);
  }

  /**
   * Update user email when connected
   * @param type
   * @param request
   * @returns
   */
  updateEmail(type: UserType, request: EmailRequest): Observable<ApiResponse<boolean>> {
    return type === UserType.PRO ? this.proService.updateEmail(request) : this.patientService.updateEmail(request);
  }

  /**
   * Update user password when connected
   * @param type
   * @param request
   * @returns
   */
  updatePassword(type: UserType, request: PasswordRequest): Observable<ApiResponse<boolean>> {
    return type === UserType.PRO ? this.proService.updatePassword(request) : this.patientService.updatePassword(request);
  }

  updateNotificationSettings(type: UserType, request: NotificationRequest): Observable<ApiResponse<boolean>> {
    return type === UserType.PRO ? this.proService.updateNotificationSettings(request) : this.patientService.updateNotificationSettings(request);
  }

  /**
   * Change password
   * @param type
   * @param request
   * @returns
   */
  changePassword(type: UserType, request: PasswordRequest): Observable<ApiResponse<boolean>> {
    return type === UserType.PRO ? this.publiqueService.changeProPassword(request) : this.publiqueService.changePatientPassword(request);
  }

  verifyEmail(type: UserType, request: VerificationData): Observable<ApiResponse<boolean>> {
    return type === UserType.PRO ? this.publiqueService.verifyProEmail(request) : this.publiqueService.verifyPatientEmail(request);
  }

  /**
   * Recover password
   * @param type
   * @param request
   * @returns
   */
  recoverPassword(type: UserType, request: EmailRequest): Observable<ApiResponse<boolean>> {
    return type === UserType.PRO ? this.publiqueService.recoverProPassword(request) : this.publiqueService.recoverPatientPassword(request);
  }

  async refreshToken(displayError: boolean = true): Promise<LoginToken> {
    if (this.ssrService.isBrowser()) {
      const token = ApplicationService.getToken();
      if (token?.token && token?.refreshToken) {
        const data: TokenInfo = jwtDecode(token.token);
        const subUrl: string = data.type === UserType.PRO ? PubliqueService.USER_PATH : PubliqueService.PATIENT_PATH;
        const request: RefreshTokenRequest = { secret: token.token, uuid: data.uuid, refreshToken: token.refreshToken };
        const res = await lastValueFrom(this.http.post<ApiResponse<LoginToken>>(`publique/${subUrl}/refresh-token`, request)).catch(() => null as never);

        if (res?.status === StatusCode.OK && res.data?.token) {
          this.initEnv(res.data, false);
          return res.data;
        }
        this.logout();
      }
      if (displayError) {
        this.notificationService.error(`core.error.${StatusCode.INVALID_TOKEN}`);
      }
    }
    return null;
  }

  setConnectedUser(user: Utilisateur | Patient) {
    this.connectedUser.set({ ...this.connectedUser(), user });
  }

  isMe(user: Utilisateur): boolean {
    return this.isConnected() && this.connected()?.id === user?.id;
  }

  initEnv(token: LoginToken, redirect: boolean = true) {
    this.token.next(token);
    if (this.ssrService.isBrowser()) {
      ApplicationService.setToken(token);
    }
    const decodedToken = CryptoService.decodeToken(token)?.token;
    this.connectedUser.set(decodedToken);
    this.loaderService.removeLoaders();
    if (redirect) {
      this.navigationService.navigate(this.dashboardLink());
    }
  }

  static hasVerificationData(data: VerificationData): boolean {
    return !!data?.token && !!data?.id && !!data?.email && !ObjectUtility.isNullOrUndefined(data?.type);
  }

  static getFullName(user: Utilisateur | Patient): string {
    let name = user?.nom ?? '';
    if (user?.prenoms) {
      name = `${user.prenoms} ${name}`;
    }
    return name;
  }

  redirectConnectedUser() {
    if (this.isConnected()) {
      this.navigationService.navigate(this.dashboardLink());
    }
  }

  goHome() {
    this.navigationService.goHome();
  }

  logout() {
    this.token.next(null);
    this.connectedUser.set(null);
    if (this.ssrService.isBrowser()) {
      ApplicationService.deleteSession(SessionKey.TOKEN);
      this.loaderService.removeLoaders();
    }
  }

}
