import { Injectable } from "@angular/core";
import { SearchParam } from '@san/tools/models';
import { Observable } from 'rxjs';
import { ApiResponse } from '../models/dto/response.dto';
import { Patient } from "../models/entity/patient";
import { Rdv } from '../models/entity/rdv';
import { FileDataResponse } from '../models/interfaces/file.interface';
import { EmailRequest, NotificationRequest, PasswordRequest } from "../models/interfaces/user.interfaces";
import { ApiBaseService } from './api-base.service';

@Injectable({
  providedIn: 'root'
})
export class PatientService extends ApiBaseService {

  constructor() {
    super('patients');
  }

  /**
   * Modifie les infos d'un patient
   * @param patient
   * @returns
   */
  edit(patient: Patient): Observable<ApiResponse<boolean>> {
    return this.http.put<ApiResponse<boolean>>(this.baseUrl, patient);
  }

  /**
   * Renvoie un patient
   * @param id
   * @param relations
   * @returns
   */
  get(id: string, relations?: string[]): Observable<ApiResponse<Patient>> {
    return this.http.post<ApiResponse<Patient>>(`${this.baseUrl}/find`, { id, relations });
  }

  /**
   * Modifie le mot de passe d'un patient
   * @param request
   * @returns
   */
  updatePassword(request: PasswordRequest): Observable<ApiResponse<boolean>> {
    return this.http.put<ApiResponse<boolean>>(`${this.baseUrl}/update-password`, request);
  }

  updateNotificationSettings(request: NotificationRequest): Observable<ApiResponse<boolean>> {
    return this.http.put<ApiResponse<boolean>>(`${this.baseUrl}/update-notification-settings`, request);
  }

  /**
   * Modifie le mail d'un patient
   * @param request
   * @returns
   */
  updateEmail(request: EmailRequest): Observable<ApiResponse<boolean>> {
    return this.http.put<ApiResponse<boolean>>(`${this.baseUrl}/update-email`, request);
  }

  /**
   * Change la photo de profil du patient
   * @param data
   * @returns
   */
  updatePhoto(data: FormData): Observable<ApiResponse<FileDataResponse>> {
    return this.http.put<ApiResponse<FileDataResponse>>(`${this.baseUrl}/update-photo`, data);
  }

  deletePhoto(id: string): Observable<ApiResponse<boolean>> {
    return this.http.delete<ApiResponse<boolean>>(`${this.baseUrl}/delete-photo/${id}`);
  }

  /**
   * Verifie si ID correspond a celui du patient connecte
   * @param id
   * @returns
   */
  isConnectedPatient(id: string): boolean {
    return id === '10';
  }

  getEvents(param: SearchParam, forExport: boolean = false): Observable<ApiResponse<Rdv[]>> {
    const query = forExport ? `?export=true` : '';
    return this.http.post<ApiResponse<Rdv[]>>(`${this.baseUrl}/rdv/list${query}`, param);
  }

}
